import React, { useEffect, useState } from "react";
import { getPastTwoWeeksMetrics, fetchUserDataByUid } from "api/db";
import Label from "components/Elements/Label";
import { DashboardContainer } from "components/Admin/styles";

export default function Churned() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getPastTwoWeeksMetrics().then(snapshot => {
      let twoWeeksData = Object.values(snapshot.val());
      let thisWeek = twoWeeksData.slice(7, 14);
      let lastWeek = twoWeeksData.slice(0, 7);

      let lastWeekUids = new Set();
      lastWeek.map(day => {
        Object.keys(day.uids).map(uid => {
          lastWeekUids.add(uid);
        });
      });

      let thisWeekUids = new Set();
      thisWeek.map(day => {
        Object.keys(day.uids).map(uid => {
          thisWeekUids.add(uid);
        });
      });

      let thisWeekUidsArray = Array.from(thisWeekUids);
      let lastWeekUidsArray = Array.from(lastWeekUids);

      let churnedUsers = [];
      lastWeekUidsArray.map(uid => {
        if (!thisWeekUidsArray.includes(uid)) {
          churnedUsers.push(uid);
        }
      });

      churnedUsers.map(uid => {
        fetchUserDataByUid(uid).once("value", function(snapshot) {
          let keyObject = { key: uid };
          let data = snapshot.val();
          const object = { ...data, ...keyObject };
          setUserData(users => users.concat(object));
        });
      });
    });
  }, []);

  return (
    <DashboardContainer>
      <Label>
        {" "}
        <a href="/admin/dashboard"> &larr; Back</a>{" "}
      </Label>
      <h2>"Churned" Users </h2>{" "}
      <small>
        definition: those who haven't started a tutorial or posted on maker log
        in the past week — but have the week before
      </small>
      {userData.length > 20 && (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>image</th>
              <th>uid</th>
              <th>username</th>
              <th>email</th>
              <th>name</th>
              <th>joined</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user, index) => {
              return (
                <tr key={user && user.key && user.key}>
                  <td>{index}</td>
                  <td>
                    <img
                      src={user && user.photoURL && user.photoURL}
                      height="40px"
                    ></img>
                  </td>
                  <td>{user.key}</td>
                  <td>
                    {" "}
                    <a href={`/user/${user.id}`}>
                      @{user && user.id && user.id}
                    </a>
                  </td>
                  <td>{user && user.email_address && user.email_address}</td>
                  <td>{user && user.username && user.username}</td>
                  <td>{user && user.created_at && user.created_at}</td>{" "}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </DashboardContainer>
  );
}
